(function($){

$(document).ready(function() {

  // $('input').on("focus", function(){
  //    $('.search-container').addClass("extend-width");
  // });

    $(".search-container input").focus(function(){
        $('.search-container').addClass('extend-width');

    }).blur(function(){
        $('.search-container').removeClass('extend-width');
    });

  $('body').show();

  //detect screen remove element
  var $containerWidth = $(window).width();
      if ($containerWidth <= 818) {
          // $('.vid').remove();
          // $('#bodymovin').remove();
      }
      if ($containerWidth > 819) {
          // $('.vid-mobile').remove();
      }

  //mobile menu 
  $('#cssmenu').parent().prepend('<div id="nav-icon1"><span></span><span></span><span></span></div>');

  $("button svg").click(function(){
      $(this).closest('.nav-children').toggleClass('active');
  });
    
  $('#nav-icon1').on('click', function(){
        
    $(this).toggleClass('open');

    var menu = $('#cssmenu').children().closest('ul');
    if (menu.hasClass('open-menu')){
      menu.removeClass('open-menu');
    }
    else {
      menu.addClass('open-menu');
    }
  });

  $('.nav-children ul li').on('click', function() {
      $('.open-menu').removeClass('open-menu');
  });

  // $('#my-slide').DrSlider({

  //         width: undefined,
  //         height: undefined,
  //         userCSS: false,
  //         transitionSpeed: 850,
  //         duration: 5000,
  //         showNavigation: false,
  //         showControl: true,
  //         classButtonNext: 'icon-forward',
  //         classButtonPrevious: 'icon-back',
  //         controlColor: '#FFFFFF',
  //         controlBackgroundColor: '#000000',
          
  //         transition: 'slide-left',
  //         showProgress: true,
  //         progressColor: '#797979',
  //         pauseOnHover: true,
  //         onReady: undefined
        
  //       }); //Yes! that's it!



  //owl carousel
  // var owl = $('.owl-carousel');
  // $(owl).owlCarousel({



  //   margin: 5,
  //   loop: false,
  //   items: 3,
  //   stagePadding: 150,
  //   nav: true,
  //   dots: false,
   
  //   responsiveClass:true,
  //   responsive:{
  //       0:{
  //           items:1,
  //           nav:true,
  //            stagePadding: 10
  //       },
  //       600:{
  //           items:2,
  //           nav:false,
  //            stagePadding: 50
  //       },
  //       1000:{
  //           items:3,
  //           nav:true,
  //           loop:false,
  //           stagePadding: 100
  //       },

  //       1440:{
  //         items:4,
  //           nav:true,
  //           loop:false,
  //           stagePadding: 100
  //       }
  //   }
  // });


  //Init the carousel
  initSlider();

  function initSlider() {




    var owl = $('#sliderOne');
    owl.owlCarousel({

      items: 1,
      //lazyLoad: true,
      loop: false,
      nav: true, 
      dots: false, 
      rewind: false,
      autoplay: true,
      
      URLhashListener:true,
      startPosition: 'URLHash',

      autoplayTimeout:15000,
      autoplayHoverPause:false,

      onInitialized: startProgressBar,
      onTranslate: resetProgressBar,
      onTranslated: startProgressBar,

      animateIn: 'fadeIn',
      animateOut: 'fadeOut',


    });


    var owltwo = $('#sliderTwo');
    owltwo.owlCarousel({

      items: 1,
      //lazyLoad: true,
      loop: false,
      nav: true,
      dots: false,
      rewind: false,
      autoplay: false,
      autoplayHoverPause: true,

      animateIn: 'fadeIn',
      animateOut: 'fadeOut',
      
      URLhashListener: true,
      startPosition: 'URLHash',

    });

  }


  

  function startProgressBar() {

    $(".slide-progress").css({
      width: "100%",
      transition: "width 15000ms"
    });
  }

  function resetProgressBar() {
    $(".slide-progress").css({
      width: 0,
      transition: "width 0s"
    });
  }


});

})(jQuery);
